@charset "UTF-8";

@import "base/variables";
@import "variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/mixins/breakpoints";
@import "components/functions";
@import "components/mixins";

.error-page {
    min-height: 50vh;
    padding: 40px 0;
    display: flex;
    align-items: center;
    text-align: center;

    @include media-breakpoint-up(md) {
        min-height: 60vh;
        padding: 50px 0;
    }

    h2 {
        @include font-size(24px, 34px);
        margin: 0 auto;
        max-width: 540px;

        @include media-breakpoint-up(md) {
            @include font-size(28px, 38px);
            max-width: 800px;
        }

        @include media-breakpoint-up(lg) {
            @include font-size(32px, 44px);
            max-width: 1000px;
        }
    }

    a {
        // stylelint-disable-next-line
        text-underline-offset: 4px;
    }

    pre {
        display: none;
    }

    strong {
        font-weight: $font-semibold;
    }

    .container {
        flex: 1 1 100%;
        max-width: 100%;
    }
}

.error-page-logo {
    height: 100px;
    margin: 0 auto 30px;
    display: block;

    @include media-breakpoint-up(md) {
        margin-bottom: 40px;
        height: 120px;
    }
}

.error-page-contact {
    p {
        @include font-size(14px, 20px);
        margin: 10px 0;
        @include clearMargins;
    }
}

.error-page-info {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        margin-bottom: 40px;
    }

    p {
        @include font-size(16px, 22px);

        @include media-breakpoint-up(md) {
            @include font-size(18px, 26px);
        }
    }
}

.error-page-more {
    margin-top: 40px;

    .button {
        min-width: 240px;
    }
}

.error-page-recommendations {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	
	.product {
		text-align: left;
	}
	
	.recommendations {
		margin: 0 0 30px 0;

		h2.title-slot {
			max-width: none;
			@include font-size(20px, 26px);
			margin: 0;
			
			@include media-breakpoint-up(md) {
	            @include font-size(24px, 32px);
	            margin-bottom: 17px;
	        }
		}
	}
}
