// Colors
$white: #fff;
$black: #000;
$sand:  #bda871;
$dark: #060719;
$dark-2: #525252;
$dark-3: #434343;
$dark-4: #191d23;
$dark-5: #161719;
$dark-6: #292929;
$dark-7: #294827;

$pink: #eb0c00;

$green: #008740;
$green-2: #006400;

// 50 shades of gray
$gray: #c6c5b9;
$gray-2: #f5f5f5;
$gray-3: #d3d3d3;
$gray-4: #e8e8e3;
$gray-5: #fafafa;
$gray-6: #ccc;
$gray-7: #767676;
$gray-8: #666;
$gray-9: #b3b5cc;
$gray-10: #f7f8f9;
$gray-11: #d9d9d9;
$gray-12: #8f8f8f;
$gray-13: #e3e5e5;
$gray-14: #555;
$gray-15: #b3b5cc;
$gray-16: #5e6366;
$gray-17: #ebebeb;
$gray-18: #545353;
$gray-19: #fcfcfa;
$gray-20: #c8bea6;
$gray-21: #686868;
$gray-22: #fbfafa;

$dark-gray: $gray;

$blue: #1771b4;
$red-2: #c90a00;

// main colors palette
$primary-50:    #feecec;
$primary-100:   #fcbdbb;
$primary-200:   #f98d8a;
$primary-300:   #f75e5a;
$primary-400:   #f42f29;
$primary-500:   #df110b;
$primary-600:   #af0d09;
$primary-700:   #7e0a06;
$primary-800:   #4e0604;
$primary-900:   #1d0201;

$grayscale-50:    #fcfcfc;
$grayscale-100:   #f3f2f2;
$grayscale-200:   #dbd7d7;
$grayscale-300:   #c3bcbc;
$grayscale-400:   #aaa1a1;
$grayscale-500:   #928686;
$grayscale-600:   #796d6d;
$grayscale-700:   #5e5555;
$grayscale-800:   #433c3c;
$grayscale-900:   #292525;

$success-50:    #e5fffc;
$success-100:   #b3fff6;
$success-200:   #4cffea;
$success-300:   #00e5ca;
$success-400:   #00b29d;
$success-500:   #007d6e;
$success-600:   #00665a;
$success-700:   #004d43;
$success-800:   #00332d;
$success-900:   #001a16;

$warning-50:    #fff5e5;
$warning-100:   #ffebcc;
$warning-200:   #ffd799;
$warning-300:   #ffc366;
$warning-400:   #ffaf33;
$warning-500:   #fd9900;
$warning-600:   #cc7c00;
$warning-700:   #995d00;
$warning-800:   #663e00;
$warning-900:   #331f00;

$danger-50:    #fcf3f3;
$danger-100:   #f7dedf;
$danger-200:   #f3c9ca;
$danger-300:   #ecacae;
$danger-400:   #df7275;
$danger-500:   #d5474c;
$danger-600:   #bf2c31;
$danger-700:   #952226;
$danger-800:   #6c191c;
$danger-900:   #420f11;

$lg-red: #c9181e;
$lg-dark: #0000001a;
$lg-dark-2: #222;

$breadcrumbs-lite: $gray-15;
$breadcrumbs-dark: $gray-8;

$primary: $primary-500;
$red: $primary-600;
$success: $success-500;
$danger: $danger-500;
$accent: $sand;
$disabled: $gray;

// Breakpoints
$grid-breakpoints: (
    xs: 0,
    xsm: 420px,
    sm: 544px,
    md: 768px,
    lg: 1024px,
    bxl: 1280px,
    xl: 1440px,
    xxl: 1800px
);

$container-max-widths: (
    xl: 1200px
);

$content-width-gutters: 20px;
$content-width-gutters2: 75px;
$max-content-breakpoint: 1200px;

$grid-gutter-width: 40px;

// Fonts

$sans-serif: 'Montserrat', 'DejaVu Sans', Verdana, sans-serif;
$serif: 'Lora', serif;
$momentsSans: 'Moments Sans', sans-serif;
$roboto: 'Roboto', sans-serif;
$rokkitt: 'Rokkitt', serif;

$base-font-family: $sans-serif;
$secondary-font-family: $serif;

$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;

$link-color: $dark;
$link-decor: underline;
$link-hover-color: $primary-400;
$link-hover-decor: underline;
$link-grayed-out-color: $disabled;
$link-alt-color: $dark;
$link-alt-decor: none;
$link-alt-hover-color: $dark;
$link-alt-hover-decor: underline;

$btn-height: '';

$outline: '';

$body-bg: $white;
$body-letter-spacing: '';

$hover: 0.2s ease;

$lg-xsmall: 576px;
$lg-xsmall-down: 575px;
$lg-small: 768px;
$lg-middle: 992px;
$lg-middle-down: 991px;
$lg-large: 1200px;

$lg-link: rgb(201, 24, 30);
$lg-red: #be3236;
$lg-black: #2a2d30;
