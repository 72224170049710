//------------------------------------
//  MIXINS
//------------------------------------

// A useful mixin to output a list of CSS styles passed in as a map
//
// Example:
//
//	//set a map variable
//	$primary-nav: (
//      padding-top: .2em,
//      margin-top: .2em,
//      line-height: 1.3,
//	);
//
//	//now output with the mixin
//	@include output-styles($primary-nav);
//
// Compiles to:
//
//	//Outputted CSS:
//	.primary-nav {
//      padding-top: .2em;
//      margin-top: .2em;
//      line-height: 1.3;
//	}

@mixin output-styles($map) {
    @each $property, $value in $map {
        #{$property}: $value;
    }
}

@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;
    @if $orientation == vertical {
        // vertical
        background: linear-gradient(to bottom, $start-color 0%, $end-color 100%);
        /* stylelint-disable-next-line function-name-case */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=0);
    } @else if $orientation == horizontal {
        // horizontal
        background: linear-gradient(to right, $start-color 0%, $end-color 100%);
        /* stylelint-disable-next-line function-name-case */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
    } @else {
        // radial
        background: radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
        /* stylelint-disable-next-line function-name-case */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
    }
}

// Line Height Sizer
// ex:  @include line-height(14px);  //sets a line height size in rems with a fallback in pxs

@mixin line-height($size) {
    line-height: $size;
    @if unit($size) == "px" {
        line-height: rem($size);
    }
}

// Letter Spacing Sizer
// ex:  @include letter-spacing(14px);  //sets a letter spacing size in rems with a fallback in pxs

@mixin letter-spacing($size) {
    letter-spacing: $size;
    @if unit($size) == "px" {
        letter-spacing: rem($size);
    }
}

// Font Sizer
// ex: @include font-size(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include font-size(15px, 20px, 0.4px); // sets a font size (15px) in rems, a line-height (20px) as a unitless value, and a letter-spacing (0.4px) in rems.
// ex3: @include font-size(14px, $breakpoint: $medium-breakpoint);  //sets a scaling font size in vw's with a fixed fallback in pxs

@mixin font-size($size: null, $lineheight: null, $letterspacing: null, $breakpoint: null) {
    @if $size != "" and $size != null {
        @if $breakpoint == null {
            font-size: $size;
            font-size: rem($size);
        } @else {
            // Get a font size in VWs that will match the given pixel font-size in the given viewport.
            font-size: $size;
            font-size: calculatevw($size, $breakpoint);
        }
        @if $lineheight != "" and $lineheight != null and unit($size) == unit($lineheight) {
            $calculated-line-height: (strip-units($lineheight) / strip-units($size)) * 1em;
            @include line-height($calculated-line-height);
        }
        @if $letterspacing != "" and $letterspacing != null {
            letter-spacing: $letterspacing;
            letter-spacing: rem($letterspacing);
        }
    }
}

//
// Secondary Font Family mixins
//

// usage examples
// ex1: @include font-alt-reg();
// ex2: @include font-alt-reg(16px);
// ex3: @include font-alt-reg(14px, 22px);

@mixin font-alt($fontsize: "", $lineheight: "", $letterspacing: "") {
    font-family: $secondary-font-family;
    font-weight: $font-regular;
    @include font-size($fontsize, $lineheight, $letterspacing);
}

// usage examples
// ex1: @include font-alt-medium();
// ex2: @include font-alt-medium(16px);
// ex3: @include font-alt-medium(14px, 22px);

@mixin font-alt-medium($fontsize: "", $lineheight: "", $letterspacing: "") {
    font-family: $secondary-font-family;
    font-weight: $font-medium;
    @include font-size($fontsize, $lineheight, $letterspacing);
}

// usage examples
// ex1: @include font-alt-bold();
// ex2: @include font-alt-bold(16px);
// ex3: @include font-alt-bold(14px, 22px);

@mixin font-alt-bold($fontsize: "", $lineheight: "", $letterspacing: "") {
    font-family: $secondary-font-family;
    font-weight: $font-dold;
    @include font-size($fontsize, $lineheight, $letterspacing);
}

// Inner page content width
//---------------------------------------

@mixin content-width() {
    box-sizing: border-box;
    margin: auto;
    width: calc(100% - #{$content-width-gutters*2});
    max-width: $max-content-breakpoint;

    @include media-breakpoint-up(lg) {
        width: calc(100% - #{$content-width-gutters2*2});
    }
}

// Clearfix
//---------------------------------------

@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

// Visually Hidden Elements
//---------------------------------------

@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Override a button's height (including borders). Optionally change the side paddings.
// ex1: @include setButtonHeight(50px);
// ex2: @include setButtonHeight(50px, 35px);
//---------------------------------------

@mixin setButtonHeight ($height: null, $sides: $btn-side-padding) {
    @if ($height and unit($height) == "px") {
        line-height: 1em; // this value allows the math below to work...
        padding: rem(($height - $btn-border-width*2 - $btn-font-size) / 2) $sides;
        min-height: rem($height);
    }
}

// Plaseholder for text inputs
// ex:  @include placehlder($white);  //sets a placeholder attribute for text input

@mixin placeholder($color) {
    &::-webkit-input-placeholder {
        opacity: 1;
        color: $color;
    }

    &::-moz-placeholder {
        opacity: 1;
        color: $color;
    }

    &:-ms-input-placeholder {
        color: $color;
    }
}

// Negative margin to "slide" under the header if necessary (homepage, PLP, any page with hero banner etc)
//---------------------------------------

@mixin headerNegativeMargin {
    margin-top: -78px;

    @include media-breakpoint-up(md) {
        margin-top: -94px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: -130px;
    }

    @include media-breakpoint-between(lg, xl) {
        margin-top: calculatevw(-130px, 1800px);
    }
}

// Styles to avoid margins on first and last element
//---------------------------------------

@mixin clearMargins {
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
